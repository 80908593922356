#signupStats .MuiSlider-markLabel {
  color: white !important;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #10B981;
  /*#d6dee1; background: linear-gradient(45deg, red, blue);*/
  border-radius: 20px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0e875f;
}

/* App styling */
.App {
  overflow-y: hidden;
}

.backgroundDark, .chartjs-render-monitor {
  background-color: rgb(33, 49, 60) !important;
  border-radius: 0.5rem !important;
}

.App main {
  padding: 15px;
  padding-bottom: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.white {
  color: white;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ag-header-cell-label {
  justify-content: center;
}

.verticalCenter {
  margin-top: auto;
  margin-bottom: auto;
}

.spacingBottom {
  margin-bottom: 3rem;
}

.position-text-transition-centered div {
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) !important;
  color: black;
}

.carousel, .carousel div, .carousel .slider {
  height: 100%;
}

.logo {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: enlarge 1s ease-in-out forwards, rotate 2s ease-in-out 1s infinite;
}

@keyframes enlarge {
  0% {
    transform: translate(-50%, -50%) scale(0.3) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
  }
}

.shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0px 0px 10px 10px rgba(216, 200, 23, 0.3);
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  30% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


image, .undraggable {
  -webkit-user-select: none !important;
  /* Safari, Chrome */
  -webkit-user-drag: none !important;
  -khtml-user-select: none !important;
  /* Konqueror */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* IE */
  user-select: none !important;
  /* CSS3 */
}

.hover-enlarge {
  transition: transform 1s !important;
}

.hover-enlarge:hover {
  transform: scale(1.05) !important;
}

.hover-background:hover {
  background-color: rgb(22, 24, 21) !important;
}

.selected-background {
  background-color: rgb(95, 143, 71) !important;
}

.selected-background:hover {
  background-color: rgb(73, 126, 47) !important;
}

.active {
  background-color: rgb(170, 172, 168) !important;
}