.achievement-grid-outer {
    margin-left: 0.025rem;
    max-width: 100%;
}

.headline-achievements {
    text-align: center;
    vertical-align: middle;
}

.achievement-card {
    height: 3rem;
    position: relative;
    background-color: rgb(33, 49, 60);
    border-radius: 0.25rem;
    padding: 1rem;
    height: max-content;
    font-family: "icomoon", "Montserrat", sans-serif;
}

.badge-image {
    z-index: 10;
    height: 3rem !important;
    width: 3rem !important;
}

.next-badge {
    position: absolute !important;
    right: 1rem;
    top: 1rem;
}

.badge-name {
    position: absolute;
    left: 4.2rem;
    top: 1.1rem;
    font-weight: 600;
    height: 1.6rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-size-adjust: none;
    color: white;
    overflow: hidden;
    white-space: nowrap;
}

.badge-bar-total {
    position: absolute;
    bottom: 1rem;
    left: 2.5rem;
    right: 2.5rem;
    height: 1.25rem;
    background-color: rgb(165, 228, 221);
    border-radius: 1.5rem;
    overflow: hidden;
    border: 1px solid black;
    cursor: pointer;
}

.badge-bar-acomplished {
    background-color: #1a90ff;
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    height: 1.25rem;
}

.badge-percentage {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.position-text-transition-centered {
    width: max-content !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    color: black;
    z-index: 10;
    font-weight: bold;
    font-family: icomoon,Montserrat,sans-serif;
    white-space: normal;
    font-weight: 600;
    line-height: 1.25rem;
    font-size: 1.25rem;
}