@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.total_board {
  margin-left: auto;
  margin-right: auto;
}

.statCardValue {
  font-weight: 700;
  font-size: 2rem;
  display: block;
  color: white;
  margin: 0;
}

.statCardType {
  display: block;
  opacity: .6;
  color: white;
}

.statCardExtraField {
  margin: 0;
  color: white;
  margin-top: 0.1rem;
}

.positive {
  color: #3ac47d;
}

.negative {
  color: #d92550;
}

.purple {
  background-color: #545cd8 !important;
}

.green {
  background-color: #13aa52 !important;
}

.yellow {
  background-color: #f7b924 !important;
}

.dark {
  background-color: #444054 ! important;
}

.orange {
  background-color: #fd7e14 !important;
}

.blue {
  background-color: #007bff !important;
}

.darkblue {
  background-color: #1d1845 !important;
}

.red {
  background-color: #f44336 !important;
}

.orchid {
  background-color: darkorchid !important;
}

.specialgreen {
  background-color: #009688 !important;
}

.green-animated {
  background-image: linear-gradient(0deg, #0ba360 0, #3cba92) !important;
}

/* UN-USED */
/* https://dashboardpack.com/live-demo-preview/?livedemo=113 */

.coral {
  background-color: lightcoral !important;
}

.bg-blue {
  background-color: #30b1ff !important;
}

.transparent {
  opacity: 0;
}

/*  */

.boardChartDark {
  background: #21313C;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);
  aspect-ratio: 5/3;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.row {
  padding: 0.25rem;
}

.col-xs {
  text-align: center;
}

.navigationImage {
  width: 3rem;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
}

.block {
  display: block !important;
}

.m-tb {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: max-content;
}

.center {
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center !important;
}

.MuiOutlinedInput-root {
  width: 300px;
}

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
}

/* Username Search */
.searchbar-settings {
  text-align: center;
  padding: 40px 20px 40px 20px;
  max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
  color: #8d9399;
}

.searchbar-outline {
  max-width: 880px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 0;
  margin: 0 auto;
}

.searchbar-input {
  font-size: 24px;
  padding: 20px 35px 20px 65px;
  border-radius: 35px;
  background: #35404d;
  color: white;
  width: 100%;
  flex-grow: 1;
  border: none;
  outline: none;
}

.searchbar-start-icon {
  left: 20px;
}

@media only screen and (max-width: 600px) {
  .searchbar-input {
    font-size: 16px;
    padding: 13px 23px 13px 43px;
    border-radius: 35px;
    background: #35404d;
    color: white;
    width: 100%;
    flex-grow: 1;
    border: none;
    outline: none;
  }

  .searchbar-start-icon {
    left: 5px;
  }
}