.inputButtonView {
    cursor: not-allowed !important;
}

.inputButtonView button {
    cursor: not-allowed !important;
}

.MuiStepper-root {
    padding-top: 2rem;
}