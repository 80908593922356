.hours-wasted-box strong {
    font-size: 6rem;
    text-shadow: 0 0 7px #fff;
    font-weight: 700;
    line-height: 1.1;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
}

.hours-wasted-box small {
    color: #888;
    line-height: 1;
    font-size: 1rem;
    display: block;
    font-weight: 700;
    position: relative;
    text-shadow: none;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.hours-wasted-box #badge-hours-wasted {
    text-decoration: none;
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.hours-wasted-box .info-box-number {
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: #fff;
    font-size: 2rem;
    margin-bottom: 0;
    display: block;
    text-align: center;
}

.hours-wasted-box .info-box-number strong {
    font-size: 2rem;
    text-shadow: none;
}

.hours-wasted-box .info-box-title small {
    font-size: 0.8rem;
}

.hours-wasted-box #text-hours-wasted-h1 {
    margin-bottom: 0;
    padding-bottom: 0;
    color: white;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.hours-wasted-box .animated-container {
    justify-content: center;
}

.hours-wasted-box #text-hours-wasted-h1 strong .animated-container::after {
    content: "h";
}

.hours-wasted-box #text-hours-wasted-h1 small .animated-container::after {
    content: "days";
    margin-left: 0.25rem;
}

.hours-wasted-box {
    background-image: linear-gradient(to right, black, #2d3035, #35373b);
    padding: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    border-radius: 0.5rem;
    position: relative;
    text-align: center;
    overflow: hidden;
}