.comparison-image {
    width: calc(105px * 1.5);
    aspect-ratio: auto calc(105 * 1.5) / calc(153 * 1.5);
    height: calc(153px * 1.5);
}

.comparison-title, .comparison-ratings {
    padding-top: 7px;
    text-align: center;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: Verdana, Arial, sans-serif;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.comparison-grid {
    text-align: center;
}

.collapsed {
    max-height: 306px;
    overflow: hidden;
}

.pointer {
    cursor: pointer!important;
}

.left {
    float: left!important;
}

.right {
    float: right!important;
}